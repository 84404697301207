import React, { useEffect, useState, Fragment, useRef } from 'react';
import axios from 'axios';
import { Dialog, Transition, Listbox } from '@headlessui/react';
import { useParams } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { ArrowRightCircleIcon, TrashIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import Datepicker from "tailwind-datepicker-react";
import authHeader from "../auth-header";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function DetailDmdGarage(props) {
  const [vhl, setVhl] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  
  const [typerdv, setTypeRDV] = useState("");
  const [selectedRDV, setselectedRDV] = useState("");
  const [remarque, setRemarque] = useState("");
  const [daterdv, setDateRDV] = useState("");
  const [show, setShow] = useState(false);

  const options = {
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    maxDate: new Date("2030-01-01"),
    minDate: new Date("2023-01-01"),
    theme: {
      background: "bg-gray-50 dark:bg-gray-700",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "",
      input: "",
      inputIcon: "",
      selected: "bg-gray-100"
    },
    icons: {
      prev: () => <span>Previous</span>,
      next: () => <span>Next</span>,
    },
    datepickerClassNames: "top-12 w-64",
    defaultDate: new Date(),
    language: "fr",
    disabledDates: [],
    weekDays: ["L", "M", "M", "J", "V", "S", "D"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Selectionner la date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  };

  const handleDateClick = () => {
    setShow(true);
  };
  
  const handleChangeTypeRDV = (value) => {
    setTypeRDV(value.name);
    setselectedRDV(value);
  };

  const handleChangeRemarque = (e) => {
    setRemarque(e.target.value);
  };

  const onChangeDateRDV = (date) => {
    setDateRDV(date);
};

  const { id } = useParams();

  const typeRDVOptions = [
    { id: 1, name: 'Check VHL' },
    { id: 2, name: 'Visite' },
    { id: 3, name: 'Accident' },
    { id: 4, name: 'Confection de plaques' },
    { id: 5, name: 'Liquide de frein' },
    { id: 6, name: 'Pneumatique' },
    { id: 7, name: 'Frein' },
    { id: 8, name: 'Distribution' },
    { id: 9, name: 'Embrayage' },
    { id: 10, name: 'Recherche de panne' },
    { id: 11, name: 'Récupération de VHL' },
    { id: 12, name: 'Autre' },
  ];

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + '/api/dmdintervgarage/' + id, { headers: authHeader() })
        .then(response => {
          setVhl({
            ...response.data,
          });
        })
        .catch(error => {
          console.log(error);
        });

      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
        .then(response => {
          setCurrentUser(response.data);
        })
        .catch(error => {
          console.log(error);
        });

      setisLoading(false);
    }
  }, [id, isLoading]);

  const deleteDmd = () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette demande ?")) {
      setisLoading(true);
      axios
        .delete(process.env.REACT_APP_BASE_URL + '/api/dmdintervgarage/' + id, { headers: authHeader() })
        .then((res) => {
          window.location = '/listdmdgarageall';
        })
        .catch((error) => {
          console.error("Erreur lors de la suppression:", error);
          setisLoading(false);
        });
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const rdv = {
      idvhl: vhl.idvhl,
      nomvhl: vhl.nomvhl,
      service: vhl.service,
      dossardorigine: vhl.dossardorigine,
      daterdv,
      remarque,
      image: vhl.image,
      typerdv,
      idusers: currentUser.username,
    };

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/add", rdv, {
        headers: authHeader(),
      })
      .then((res) => {        
        window.location = "/listdmdgarageall";
      })
      .catch((error) => {
        console.error(error);
      }); 
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* En-tête avec image et informations principales */}
        <div className="bg-white rounded-2xl shadow-lg overflow-hidden mb-6">
          <div className="relative h-48 sm:h-64">
            <img
              className="absolute inset-0 w-full h-full object-cover"
              src={vhl.image}
              alt={vhl.nomvhl}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
            <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
              <h1 className="text-2xl sm:text-3xl font-bold">{vhl.nomvhl}</h1>
              <p className="mt-2 text-gray-200 font-semibold">{vhl.dossardorigine}</p>
            </div>
          </div>

          <div className="p-6">
            <div className="flex flex-wrap gap-3 mb-6">
              <span className="inline-flex items-center rounded-full bg-red-50 px-4 py-2 text-sm font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                {vhl.typedemande}
              </span>
              <span className="inline-flex items-center rounded-full bg-blue-50 px-4 py-2 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                {vhl.degreurgence}
              </span>
            </div>

            {/* Grille d'informations */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div className="space-y-6">
                <div className="bg-gray-50 rounded-xl p-4 hover:bg-gray-100 transition-colors">
                  <dt className="text-sm font-medium text-gray-500">Kilométrage</dt>
                  <dd className="mt-1 text-lg font-semibold text-gray-900">{vhl.km}</dd>
                </div>
                <div className="bg-gray-50 rounded-xl p-4 hover:bg-gray-100 transition-colors">
                  <dt className="text-sm font-medium text-gray-500">Nom</dt>
                  <dd className="mt-1 text-lg font-semibold text-gray-900">{vhl.nom}</dd>
                </div>
                <div className="bg-gray-50 rounded-xl p-4 hover:bg-gray-100 transition-colors">
                  <dt className="text-sm font-medium text-gray-500">N° de TPH</dt>
                  <dd className="mt-1 text-lg font-semibold text-gray-900">{vhl.nTPH}</dd>
                </div>
              </div>
              <div className="space-y-6">
                <div className="bg-gray-50 rounded-xl p-4 hover:bg-gray-100 transition-colors">
                  <dt className="text-sm font-medium text-gray-500">Position du CDB</dt>
                  <dd className="mt-1 text-lg font-semibold text-gray-900">{vhl.positioncdb}</dd>
                </div>
                <div className="bg-gray-50 rounded-xl p-4 hover:bg-gray-100 transition-colors">
                  <dt className="text-sm font-medium text-gray-500">Position du VHL</dt>
                  <dd className="mt-1 text-lg font-semibold text-gray-900">{vhl.positionvhl}</dd>
                </div>
              </div>
            </div>

            {/* Section détails */}
            <div className="mt-8">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Détails / Explications</h3>
              <div className="bg-gray-50 rounded-xl p-6">
                <p className="text-gray-700 whitespace-pre-wrap">
                  {vhl.details || "Aucun détail fourni"}
                </p>
              </div>
            </div>

            {/* Bouton RDV pour le garage */}
            {currentUser.service === "GARAGE" && (
              <div className="mt-4 flex sm:flex-row justify-center sm:justify-center">
                <button
                  type="button"
                  onClick={() => setOpen(true)}
                  className="inline-flex items-center mb-2 px-6 py-3 border border-transparent text-base font-medium rounded-xl shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                >
                  <ArrowRightCircleIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                  Planifier un RDV
                </button>
              </div>
            )}

            {currentUser.roles === "Admin" && (
              <div className= "mt-4 flex justify-center">
              <button
              type="button"
              onClick={deleteDmd}
              className="inline-flex items-center mb-2 sm:ml-2 px-6 py-3 border border-transparent text-base font-medium rounded-xl shadow-sm text-white bg-red-600 hover:bg-red-700 focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200"
            >
              <TrashIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Supprimer la demande
            </button>
            </div>
            )}
          </div>
        </div>

        {/* Modal RDV */}
        <Transition.Root show={open} as={Fragment}>
          <Dialog 
            as="div" 
            className="fixed inset-0 z-50 overflow-y-auto" 
            initialFocus={cancelButtonRef} 
            onClose={setOpen}
          >
            <div className="flex min-h-screen items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity" />
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all sm:my-8 w-full max-w-lg">
                  {/* En-tête du modal avec image de fond */}
                  <div className="relative h-32 bg-gradient-to-r from-blue-600 to-blue-800">
                    <div className="absolute inset-0 bg-black/20"></div>
                    <div className="absolute bottom-0 left-0 right-0 p-6">
                      <Dialog.Title as="h3" className="text-2xl font-bold text-white">
                        Planifier un RDV
                      </Dialog.Title>
                      <p className="mt-1 text-blue-100">
                        {vhl.nomvhl} - {vhl.dossardorigine}
                      </p>
                    </div>
                  </div>

                  {/* Contenu du formulaire */}
                  <div className="px-6 py-4">
                    <form onSubmit={onSubmit} className="space-y-6">
                      {/* Type de RDV */}
                      <div>
                        <Listbox value={selectedRDV} onChange={handleChangeTypeRDV}>
                          {({ open }) => (
                            <>
                              <Listbox.Label className="block text-sm font-medium text-gray-700 mb-2">
                                Type de RDV
                              </Listbox.Label>
                              <div className="relative">
                                <Listbox.Button className="relative w-full cursor-pointer rounded-xl border border-gray-300 bg-white py-3 pl-4 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
                                  <span className="block truncate">
                                    {selectedRDV.name || "Sélectionner un type"}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {typeRDVOptions.map((rdv) => (
                                      <Listbox.Option
                                        key={rdv.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                            'relative cursor-pointer select-none py-3 pl-4 pr-9 hover:bg-blue-50 hover:text-blue-600 transition-colors'
                                          )
                                        }
                                        value={rdv}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                              {rdv.name}
                                            </span>
                                            {selected ? (
                                              <span
                                                className={classNames(
                                                  active ? 'text-white' : 'text-blue-600',
                                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                                )}
                                              >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                      </div>

                      {/* Date */}
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Date du RDV
                        </label>
                        <div className="relative">
                          <Datepicker
                            options={options}
                            onChange={onChangeDateRDV}
                            show={show}
                            setShow={setShow}
                          >
                            <div className="relative">
                              <input
                                type="text"
                                onClick={handleDateClick}
                                value={daterdv ? new Date(daterdv).toLocaleDateString('fr-FR', {
                                  day: 'numeric',
                                  month: 'long',
                                  year: 'numeric'
                                }) : ''}
                                readOnly
                                className="w-full rounded-xl border-gray-300 py-3 pl-4 pr-10 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm cursor-pointer"
                                placeholder="Sélectionner une date"
                              />
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <svg className="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                  <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                                </svg>
                              </div>
                            </div>
                          </Datepicker>
                        </div>
                      </div>

                      {/* Détails / Explications */}
                      <div>
                        <label htmlFor="remarque" className="block text-sm font-medium text-gray-700 mb-2">
                          Détails / Explications
                        </label>
                        <div>
                          <textarea
                            rows={4}
                            name="remarque"
                            id="remarque"
                            className="block w-full rounded-xl border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm resize-none"
                            placeholder="Ajoutez des détails sur l'intervention..."
                            value={remarque}
                            onChange={handleChangeRemarque}
                          />
                        </div>
                      </div>
                    </form>
                  </div>

                  {/* Footer avec boutons */}
                  <div className="bg-gray-50 px-6 py-4 flex flex-col sm:flex-row-reverse gap-2">
                    <button
                      type="button"
                      onClick={onSubmit}
                      className="w-full sm:w-auto inline-flex justify-center items-center rounded-xl bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
                    >
                      <CheckIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                      Confirmer le RDV
                    </button>
                    <button
                      type="button"
                      onClick={() => setOpen(false)}
                      className="w-full sm:w-auto inline-flex justify-center items-center rounded-xl bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}

export default DetailDmdGarage;
