import { React, useState, useEffect } from "react";
import { CalendarDaysIcon, ChevronDownIcon, NewspaperIcon, WrenchScrewdriverIcon } from '@heroicons/react/20/solid'
import axios from "axios";
import authHeader from "./auth-header";

import { initializeApp } from "firebase/app";
import { getDatabase, ref, runTransaction } from "firebase/database";
import addNotification from 'react-push-notifiy';


function Home() {
  const [currentUser, setCurrentUser] = useState({});
  const [dmdGarage, setDmdGarage] = useState([]);
  const [rdvGarage, setRdvGarage] = useState([]);
  const [vhl, setVhl] = useState([]);


  // Firebase RealTime Database pour l'ouverture de l'armoire FOR
const firebaseConfig = {
  apiKey: "AIzaSyBTxx5fm13x85Gege_yYgdjFM5GgiogREo",
  authDomain: "armoire-33c3b.firebaseapp.com",
  databaseURL: 'https://armoire-33c3b-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: "armoire-33c3b",
  storageBucket: "armoire-33c3b.appspot.com",
  messagingSenderId: "869239049975",
  appId: "1:869239049975:web:715bde33ef2f9ba2e7cdf2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);


// Lorsque le bouton est pressé
function handleButtonClick() {
  const histoarmoire = {
    
    user: currentUser.username,
    service: currentUser.service,
    date: new Date(),
  };

  axios
        .post(
          process.env.REACT_APP_BASE_URL + "/api/histoarmoire/add/",
          histoarmoire,
          { headers: authHeader() }
        )
        .catch((error) => {
          console.log(error);
        });

  const solenoidRef = ref(database, 'Armoire/solenoid');

  // Utilisation d'une transaction pour mettre à jour la valeur
  runTransaction(solenoidRef, (currentData) => {
    return currentData === true ? false : true;
  })
    .then((result) => {
      console.log('Mise à jour réussie ! Nouvelle valeur de solenoid :', result.snapshot.val());
    })
    .catch((error) => {
      console.error('Erreur lors de la mise à jour :', error);
    });
}

  const serviceImages = {
    FOR: "/images/for.jpg",
    EMOPS: "/images/home2.jpeg",
    FSP: "/images/home3.jpeg",
    FI: "/images/home4.jpg",
    SMS: "/images/home5.jpg",
    CNFIS: "/images/home7.jpg",
    GARAGE: "/images/garage.jpg",
    DT: "/images/home8.jpg",
    "R&D": "/images/home8.jpg",
    AMS: "/images/home9.jpeg",
    CYNO: "/images/home10.jpeg",
    CAB: "/images/home11.jpeg",
    MAG: "/images/home11.jpeg",
    FA: "/images/home11.jpeg",
    EMSF: "/images/home11.jpeg",
  };

  const imagePath = serviceImages[currentUser.service];

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios.get(process.env.REACT_APP_BASE_URL + '/api/dmdintervgarage/', { headers: authHeader() })
      .then(response => {
        setDmdGarage(response.data);
      })
      .catch(error => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/", { headers: authHeader() })
      .then((response) => {
        setRdvGarage(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function countMissingUsernames() {
    if (currentUser.roles === 'Admin') {
      const filteredDmdGarage = dmdGarage.filter(vehicle => {
        return vehicle.service === currentUser.service && !vehicle.idusers.includes(currentUser.username);
      });
  
      return filteredDmdGarage.length;
    } else {
      return 0;
    }
  }

  function countMissingUsernamesGarage() {
      const filteredDmdGarage = dmdGarage.filter(vehicle => {
        return !vehicle.idusers.includes(currentUser.username);
      });
  
      return filteredDmdGarage.length;
  }

  const Badge = () => (
    <span className="absolute top-0 right-0 -mt-3 -mr-3 inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-700">
      {countMissingUsernames()} non lus
    </span>
  );

  const BadgeGarage = () => (
    <span className="absolute top-0 right-0 -mt-3 -mr-3 inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-700">
      {countMissingUsernamesGarage()} non lus
    </span>
  );

  function countMissingUsernamesRDV() {
    if (currentUser.roles === 'Admin') {
      const filteredRDVGarage = rdvGarage.filter(vehicle => {
        return vehicle.service === currentUser.service && !vehicle.idusers.includes(currentUser.username);
      });
  
      return filteredRDVGarage.length;
    } else {
      return 0;
    }
  }

  const BadgeRDV = () => (
    <span className="absolute top-0 right-0 -mt-3 -mr-3 inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-700">
      {countMissingUsernamesRDV()} non lus
    </span>
  );

  const logOut = () => {
    localStorage.removeItem("user");
  };

  return (
    <div className="min-h-screen bg-gray-50 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        {/* Header avec image */}
        <div className="relative rounded-xl overflow-hidden mb-5 shadow-lg">
          {imagePath && (
            <div className="aspect-w-16 aspect-h-9">
              <img 
                className="object-cover w-full h-full" 
                src={imagePath} 
                alt="Home" 
              />
            </div>
          )}
        </div>

        {/* Bouton Armoire FOR */}
        {currentUser.service === "FOR" && (
          <div className="mb-6">
            <button
              type="button"
              onClick={handleButtonClick}
              className="w-full sm:w-auto px-6 py-3 text-sm font-medium text-indigo-900 bg-white rounded-lg border-2 border-indigo-900 shadow-sm hover:bg-indigo-500 hover:text-white transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="flex items-center justify-center">
                Ouvrir l'armoire clés
              </span>
            </button>
          </div>
        )}

        {/* Grille de boutons principale */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* Liste des véhicules */}
          <button
            onClick={() => (window.location.href = "/listvhl")}
            className="flex items-center justify-center p-4 bg-indigo-900 text-white rounded-lg shadow-md hover:bg-indigo-700 transition-colors duration-200"
          >
            <span className="text-sm font-medium uppercase">Liste des véhicules</span>
          </button>

          {currentUser.service !== "GARAGE" && (
            <>
              {/* Véhicules disponibles */}
              <button
                onClick={() => (window.location.href = "/disponibles")}
                className="flex items-center justify-center p-4 bg-green-700 text-white rounded-lg shadow-md hover:bg-green-600 transition-colors duration-200"
              >
                <span className="text-sm font-medium uppercase">Véhicules disponibles</span>
              </button>

              {/* Véhicules en mission */}
              <button
                onClick={() => (window.location.href = "/mission")}
                className="flex items-center justify-center p-4 bg-red-700 text-white rounded-lg shadow-md hover:bg-red-600 transition-colors duration-200"
              >
                <span className="text-sm font-medium uppercase">Véhicules en mission</span>
              </button>

              {/* Demande intervention Garage */}
              <button
                onClick={() => (window.location.href = "/dmdintervgarage")}
                className="flex items-center justify-center p-4 bg-white text-red-700 border-2 border-red-700 rounded-lg shadow-md hover:bg-red-50 transition-colors duration-200"
              >
                <WrenchScrewdriverIcon className="h-5 w-5 mr-2" />
                <span className="text-sm font-medium uppercase">Demande d'interv. Garage</span>
              </button>

              {/* Réservation VHL */}
              <button
                onClick={() => (window.location.href = "/reservations")}
                className="flex items-center justify-center p-4 bg-white text-blue-900 border-2 border-blue-900 rounded-lg shadow-md hover:bg-blue-50 transition-colors duration-200"
              >
                <CalendarDaysIcon className="h-5 w-5 mr-2" />
                <span className="text-sm font-medium uppercase">
                  {currentUser.service === "FOR" ? "Réservation VHL liaison" : "Réservation VHL"}
                </span>
              </button>

              {/* Divers avec Badge */}
              <div className="relative">
                <button
                  onClick={() => (window.location.href = "/divers")}
                  className="w-full flex items-center justify-center p-4 bg-white text-blue-900 border-2 border-blue-900 rounded-lg shadow-md hover:bg-blue-50 transition-colors duration-200"
                >
                  <span className="text-sm font-medium uppercase">Divers</span>
                </button>
                <Badge />
              </div>
            </>
          )}

          {/* Section Garage */}
          {currentUser.service === "GARAGE" && (
            <>
              <button
                onClick={() => (window.location.href = "/calendriertachesv2")}
                className="flex items-center justify-center p-4 bg-white text-blue-900 border-2 border-blue-900 rounded-lg shadow-md hover:bg-blue-50 transition-colors duration-200"
              >
                <span className="text-sm font-medium uppercase">Planning</span>
              </button>

              <button
                onClick={() => (window.location.href = "/rdvvhl")}
                className="flex items-center justify-center p-4 bg-white text-green-600 border-2 border-green-600 rounded-lg shadow-md hover:bg-green-50 transition-colors duration-200"
              >
                <span className="text-sm font-medium uppercase">RDV VHL</span>
              </button>

              <div className="relative">
                <button
                  onClick={() => (window.location.href = "/listdmdgarageall")}
                  className="w-full flex items-center justify-center p-4 bg-white text-red-600 border-2 border-red-600 rounded-lg shadow-md hover:bg-red-50 transition-colors duration-200"
                >
                  <span className="text-sm font-medium uppercase">Demandes d'intervention Garage</span>
                </button>
                <BadgeGarage />
              </div>

              <button
                onClick={() => (window.location.href = "/vhlsgarage")}
                className="flex items-center justify-center p-4 bg-white text-yellow-900 border-2 border-yellow-900 rounded-lg shadow-md hover:bg-yellow-50 transition-colors duration-200"
              >
                <span className="text-sm font-medium uppercase">Véhicules au garage</span>
              </button>
            </>
          )}

          {/* RDV VHL Service (Admin) */}
          {currentUser.roles === "Admin" && currentUser.service !== "GARAGE" && (
            <div className="relative">
              <button
                onClick={() => (window.location.href = "/rdvvhlservice")}
                className="w-full flex items-center justify-center p-4 bg-white text-green-600 border-2 border-green-500 rounded-lg shadow-md hover:bg-green-50 transition-colors duration-200"
              >
                <span className="text-sm font-medium uppercase">RDV VHL</span>
              </button>
              <BadgeRDV />
            </div>
          )}

          {/* Fiches réflexes */}
          <button
            onClick={() => (window.location.href = "/tutos")}
            className="flex items-center justify-center p-4 bg-white text-blue-900 border-2 border-blue-900 rounded-lg shadow-md hover:bg-blue-50 transition-colors duration-200"
          >
            <NewspaperIcon className="h-5 w-5 mr-2" />
            <span className="text-sm font-medium uppercase">Fiches réflexes</span>
          </button>

          {/* Admin Button */}
          {currentUser.roles === "Admin" && (
            <button
              onClick={() => (window.location.href = "/pageadmin")}
              className="flex items-center justify-center p-4 bg-white text-red-700 border-2 border-red-700 rounded-lg shadow-md hover:bg-red-50 transition-colors duration-200"
            >
              <span className="text-sm font-medium uppercase">ADMIN</span>
            </button>
          )}
        </div>

        {/* Footer */}
        <div className="mt-10 text-center">
          {currentUser.username === undefined ? (
            <button
              type="button"
              onClick={() => (window.location.href = "/login")}
              className="px-6 py-3 text-sm font-medium text-blue-900 bg-white rounded-lg shadow-sm hover:bg-blue-500 hover:text-white transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <span className="flex items-center justify-center">
                Se connecter
              </span>
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                logOut();
                window.location.href = "/login";
              }}
              className="px-6 py-3 text-sm font-medium text-blue-900 bg-white rounded-lg shadow-sm hover:bg-blue-500 hover:text-white transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <span className="flex items-center justify-center">
                Se déconnecter
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
